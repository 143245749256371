.page.-professional.-clients {
  margin-bottom: 4em;
}

.client-invite-client {
  display: inline-flex;
  text-align: center;
  margin-top: 20px;
  color: #fff;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.625rem 1.5rem;
  background-color: #ff583c;
  font-weight: 600;
  &.no-margin {
    margin-top: 0;
  }
}

.client-invite-client-outlined {
  display: inline-flex;
  text-align: center;
  margin-top: 20px;
  color: #fff;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.625rem 1.5rem;
  border: 1px solid #ff583c;
  font-weight: 600;
  color: #ff583c;
  &.no-margin {
    margin-top: 0;
  }
}

.client-container-invite-link {
  text-align: center;
}
