//
// Tooltips
// --------------------------------------------------

[data-tooltip] {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &:before,
  &:after {
    transition: all 0.2s ease-out;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &:before {
    @include arrow-down(7px, $textColor);
    content: '';
    top: 0;
    left: 50%;
    margin-left: -3.25px;
    z-index: 10001;
    bottom: -10px;
  }
  &:after {
    box-sizing: border-box;
    content: attr(data-tooltip);
    padding: 0.5em 0.75em;
    border-radius: 3px;
    font-size: 0.95em;
    z-index: 10000;
    left: 50%;
    bottom: 100%;
    margin-left: -7.5em;
    margin-top: 10px;
    width: 15em;
    line-height: 120%;
    background: $textColor;
    color: $baseColor;
  }
  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

[data-tooltip-liked] {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &:before,
  &:after {
    transition: all 0.2s ease-out;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    box-sizing: border-box;
    content: attr(data-tooltip-liked);
    padding: 0.5em 0.75em;
    border-radius: 10px;
    border: 1px solid $primaryColor;
    font-size: 0.95em;
    z-index: 10000;
    left: 0;
    bottom: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 7em;
    width: max-content;
    max-width: 14em;
    line-height: 120%;
    background-color: white;
    color: $primaryColor;
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
}
