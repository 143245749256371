.section-feed {
  background: $primaryLighterColor;
  padding: 0 0 2em 0;
  clear: both;
  @include respond-to('mobile') {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .feed-title {
    @include columns(12);
    margin-bottom: 1em;
  }
  &.dashboard-section {
    @include respond-to('mobile') {
      margin-top: 3em;
    }
    h2 {
      padding: 0 0.5em;
    }
  }
}
