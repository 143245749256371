.post-single {
  .post {
    @include columns(12);
    background: $baseLightColor;
    margin: 0 0 1em;
    border-radius: $radiusOuter;
    overflow: hidden;
    box-shadow: $shadowShallow;
    @include respond-to('mobile') {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    &__header {
      display: flex;
      align-items: baseline;
      padding: 1em;
      position: relative;
      transition: background-color 1.5s ease-out;
      &.unread {
        background: $secondaryLighterColor;
      }
    }
    &__avatar {
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin-right: 8px;
    }
    &__title {
      flex: 1 1;
      font-size: 16px;
      line-height: 18px;
      color: $textLightColor;
      margin-right: 24px;
      &__link-title {
        color: $postTitleColor;
        text-decoration: none;
        &:hover {
          color: $primaryColor;
          text-decoration: underline;
        }
      }
    }
    &__time {
      flex: 1 1 1fr;
      color: $textLightColor;
      font-size: 14px;
      text-align: right;
      a {
        color: $textLightColor;
      }
    }
    &__delete,
    &__pin,
    &__edit {
      color: $textLightColor;
      cursor: pointer;
      margin-left: 12px;
      &:hover,
      &:focus {
        color: $primaryColor;
      }
    }
    &__edited {
      color: $textLightColor;
      font-size: 14px;
      padding: 0 1em 0.5em 1em;
    }
    &__pin {
      &.active {
        color: $primaryColor;
      }
      &.disabled {
        color: $textLightColor;
        cursor: default;
        &:hover,
        &:focus {
          color: $textLightColor;
        }
      }
    }
    .user-link + .user-link::before {
      content: ',';
      margin-right: 2px;
    }
    .goal-link + .goal-link::before {
      content: ',';
      margin-right: 2px;
    }
    .text {
      padding: 1em;
      color: $textColor;
      line-height: 1.5rem;
      white-space: pre-wrap;
    }
    .meta {
      padding: 1em 1em;
      .item {
        display: inline-block;
        color: $textLightColor;
        margin-right: 1em;
        &.-stars {
        }
        &.-primary {
          color: $primaryColor;
        }
        &.-time {
          float: right;
          margin-right: 0;
        }
      }
    }
    .media {
      background: $primaryColor; // height:15rem;
      img {
        width: 100%;
      }
    }
    .files {
      display: flex;
      margin: 1em;

      &__link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: $radiusInner;
        padding: 5px;
        margin-right: 0.5em;
        transition: 0.2s linear background;

        &:hover {
          background: $secondaryLighterColor;
        }

        svg {
          width: 22px;
          margin-left: -2px;
          margin-right: 4px;
        }
      }

      &__name {
        flex: 1;
        font-size: 0.8rem;
        text-align: center;
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        color: $textColor;
      }
    }
    .action {
      // background: $baseColor;
      padding: 0.8em 1em;
      @include respond-to('mobile') {
        display: flex;
        justify-content: space-between;
      }
      .button {
        margin: 0 0.5em 0 0;
        &.-info {
          float: right;
        }

        @include respond-to('mobile') {
          margin: 0 0 0 0;
          width: 100%;
          white-space: nowrap;
          flex: 0;
        }
      }
    }
    .comments {
      background: $baseColor;

      .comment-wrap {
        overflow: hidden;
      }

      .comment-create {
        padding: 1em 1em 0.25em;
      }
      .comment-single {
        padding: 1em 1em 0.25em;
        background: $baseLightColor;
        border-top: 1px solid $baseColor;
        &:last-child {
          .comment {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
      }
      .showmore {
        cursor: pointer;
        padding: 1em;
        color: $primaryColor;
        margin: 0 0 1em;
      }
      input,
      textarea {
        width: 100%;
      }
    }
  }
}

.post-pinned {
  margin: 0 0 0 0;
  padding: 0 0 0 0;

  * {
    margin-top: 1em;
  }

  a {
    color: $textColor;
    text-decoration: underline;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    &:hover,
    &:focus {
      color: $primaryColor;
      text-decoration: none;
    }
  }
}

.comments-textarea {
  margin-bottom: 12px;
  min-height: 200px;

  &-tagged {
    background: rgba($secondaryColor, 0.25);
    cursor: pointer;
    padding: 2px;
    margin-left: -2px;
    border-radius: $radiusInner;
    margin-right: -2px;
    color: $secondaryColor;
    pointer-events: none;
  }

  &__highlighter {
    z-index: 1;
    pointer-events: none;

    &__substring {
      visibility: inherit !important;
      color: $textColor;
    }
  }

  &__input,
  &__highlighter {
    padding: 14px 16px 28px 16px;
    font-size: 16px;
    font-family: $headingFont;
    line-height: 22px;
  }
  &__input {
    background-color: #fff;
    transition: 0.3s ease-in-out;
    border: 1px solid #dedede;
    color: white;
    caret-color: $textColor;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid #3fb9de;
    }
    &::placeholder {
      color: lighten($textLightColor, 15%);
      font-size: 16px;
      line-height: 18px;
      font-family: $headingFont;
    }
  }
  &__suggestions {
    background-color: #fff;
    box-shadow: $shadowDeep;
    border-radius: $radiusOuter;
    font-size: 14px;
    overflow: hidden;
    background-clip: padding-box;
    &__item {
      display: flex;
      align-items: center;
      padding: 6px 16px 6px 10px;

      &--focused {
        background-color: $secondaryLighterColor;
      }

      &__image {
        flex: 0 0 28px;
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }

      &__text {
        flex: 1 1;
      }
    }
  }
}

// temp
.post-create {
  .post-create--form {
    background: #ffffff;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
    .comments-textarea__input {
      border: none;
    }
  }
  .upload {
    // position: relative;
    display: inline-block;
    // margin: 0 0.4rem 0.4rem 0;
    // width: 25%;

    position: relative;
    width: 48%;
    height: 0;
    padding-bottom: 48%;
    margin: 1%;

    svg {
      width: 32px;
      margin-left: -2px;
      margin-right: 4px;
    }
    .upload-file {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: $radiusInner;
      background: $secondaryLighterColor;
      padding: 5px;
    }
    .upload-filename {
      flex: 1;
      font-size: 0.8rem;
      text-align: center;
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: pre;
      overflow: hidden;
      color: $textColor;
    }
    .progress-bar {
      margin: 3px 0 0 0;
    }

    &.-image {
      .upload-image {
        width: 100%;
        height: auto;
        display: block;

        display: inline-block;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }

      img {
        border-radius: $radiusInner;
        background: $secondaryLighterColor;
        max-width: 100%;
        max-height: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    &.-video {
      width: 100%;
      video {
        width: 100%;
        border-radius: $radiusInner;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background: $secondaryLighterColor;
      }
    }

    &:hover .upload-delete {
      opacity: 1;
    }
  }
  .upload-delete {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $secondaryColor;
    font-size: 22px;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
    transition: 0.3s linear opacity;
    opacity: 0;

    i {
      width: 24px;
      height: 24px;
      display: block;
      line-height: 24px;
      background: white;
      border-radius: 12px;
      text-align: center;

      &::before {
        margin: 0;
      }
    }
  }
  textarea {
    width: 100%;
  }
  .extensions {
    overflow: hidden;
  }
  .article-action {
    .file-upload {
      display: inline-block;
      margin: 0 0.5rem 0 0;
    }
  }
  .profile-select-avatar {
    img: {
      width: '54px';
      height: '54px';
    }
  }
}
