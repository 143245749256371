//
// Global Settigns
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body {
  background: $primaryLighterColor;
  color: $textColor;
  overflow-x: hidden;
}

.container {
  @include container();
  &.noclearfix {
    @include noclearfix;
  }
}

.error {
  color: $errorColor;
}

.success {
  color: $successColor;
}

.right {
  float: right;
}

.auth {
  overflow: visible;
}

.pointer {
  cursor: pointer;
}
